import * as React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import { breakpoint, colors } from '../styles/variables'
import SmallBanner from '../components/SmallBanner'
import logo from '../assets/images/logos/logo.png'
import { PageContent } from '../components/PageContent'
import { Link } from 'gatsby'
import { useForm } from '@formspree/react';
import { PageHead } from '../components/PageHead'

//import { useGoogleReCaptcha } from 'react-google-recaptcha';

const Wrapper = styled.div`
  padding-bottom: 4rem;
  padding-top: 3rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media ${breakpoint.md} {
    grid-template-columns: 1.3fr 1fr;
  }
  @media ${breakpoint.lg} {
    padding: 3rem 0 7rem;
  }
`

const AddressWrapper = styled.div`
`
const StyledForm = styled.form`
  display: grid;
  background-color: #ffffff;
  //box-shadow: 1px 1px 12px 2px #bfbfbf4f;
  padding: 1.5rem 1rem;
  border: 1px solid silver;

  @media ${breakpoint.sm} {
    padding: 2rem 1.5rem;
  }

  @media ${breakpoint.md} {
    padding: 2.5rem 2rem;
  }

  .link {
    justify-self: start;
    font-size: 0.875rem;
  }

  .button {
    justify-self: end;
  }
`

const StyledLabel = styled.label`
  margin-bottom: 0.25rem;
  font-weight: 500;
  color: #5e5e5e;
`

const StyledInput = styled.input`
  padding: 0.5rem;
  outline: none;
  border: none;
  background-color: ${colors.background};
  margin-bottom: 1rem;
  border-bottom: 1px solid silver;

  @media ${breakpoint.sm} {
    margin-bottom: 1.25rem;
  }

  @supports selector(:focus-visible) {
    custom-button:focus {
      outline: none;
      background: transparent;
    }
  }

  &:focus-visible, &:focus {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${colors.primary};
  }
`
const StyledTextArea = styled.textarea`
  padding: 0.5rem;
  background-color: ${colors.background};
  outline: none;
  border: none;
  border-bottom: 1px solid silver;

  @supports selector(:focus-visible) {
    custom-button:focus {
      outline: none;
      background: transparent;
    }
  }

  &:focus-visible, &:focus {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${colors.primary};
  }
`

const Contact = styled.a `
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: #5e5e5e;
  margin: 1em 0 1em 0.5em;
  transition: color 0.3s ease;

  .icon {
    transition: color 0.3s ease;
  }

    &:hover {
      color: ${colors.primary};

      .icon {
        color: ${colors.primary};
      }
  }
`

const ContactIcon = styled.span `
  font-size: 1.25rem;
  color: #5e5e5e;
`
const BusinessCard = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  //box-shadow: 1px 1px 12px 2px #bfbfbf4f;
  border: 1px solid silver;
`

const TermsConsent = styled.p`
  font-size: 14px;
`

const SuccessMessage = styled.p`
  text-align: center;
  font-size: 1.25rem;

  @media ${breakpoint.sm} {
    font-size: 1.5rem;
  }
  `

const ReturnLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.4s ease;
  display: block;
  text-align: center;

  &:hover {
    color: #b06f2e;
  }

  @media ${breakpoint.sm} {
    font-size: 1rem;
  }
`

const MessageContainer = styled.div`
  @media ${breakpoint.md} {
    margin-top: 4rem;
  }
`

const Intro = styled.p `
  margin-bottom: 0;

  @media ${breakpoint.md} {
    margin-top: 2rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 4rem;
  }
`

const ContactPage = () => {
    const [state, handleSubmit] = useForm("meqdoqky");


  if (state.succeeded) {
      return (
        <>
          <SmallBanner/>
          <Container>
            <PageContent>
              <MessageContainer>
                <SuccessMessage>Děkuji za zprávu, budu Vás kontaktovat.</SuccessMessage>
                <ReturnLink to="/">Zpět na hlavní stránku</ReturnLink>
              </MessageContainer>
            </PageContent>
          </Container>
        </>
      );}

  return (
    <>
      <SmallBanner title="Kontakt"/>
      <PageContent>
        <Container>
          <Intro>Zanechte mi zprávu a já se Vám ozvu, nebo vyplňte <a href="https://docs.google.com/forms/d/e/1FAIpQLSexF3RsjZwtwM1USCIFolI4eFVhTI_ByGMGcPwNfSOW4Cj8Cw/viewform?usp=pp_url">nezávazný předběžný formulář</a>.</Intro>
          <Wrapper>
            <StyledForm onSubmit={handleSubmit}>
              {/* Name */}
              <StyledLabel htmlFor="name">Jméno*</StyledLabel>
              <StyledInput type="text" name="name" id="name" required/>
             {/*  <ValidationError prefix="name" field="name" errors={state.errors}/> */}

              {/* Surname */}
              <StyledLabel htmlFor="surname">Příjmení*</StyledLabel>
              <StyledInput type="text" name="surname" id="surname" required/>
              {/* <ValidationError prefix="surname" field="surname" errors={state.errors}/> */}

              {/* Email */}
              <StyledLabel htmlFor="email">Email*</StyledLabel>
              <StyledInput type="email" name="email" id="email" required/>
              {/* <ValidationError prefix="email" field="email" errors={state.errors}/> */}

              {/* Message */}
              <StyledLabel htmlFor="message">Text vaší zprávy</StyledLabel>
              <StyledTextArea name="message" id="message" rows="5"/>

              <TermsConsent>Odesláním formuláře souhlasíte s <Link to="/terms" >podmínkami zpracování osobních údajů</Link>.</TermsConsent>


              <button className="link" type="reset" value="Vymazat formulář">Vymazat formulář</button>
              <button className="button" type="submit" disabled={state.submitting}>Odeslat</button>
            </StyledForm>
            <AddressWrapper>
              <BusinessCard>
                <img src={logo} alt="" />
                <Contact href="mailto:julia@cestu-j.cz"><ContactIcon className="icon icon-mail"></ContactIcon> <span >julia@cestu-j.cz</span></Contact>
                <Contact href="tel:+420739649786"><ContactIcon className="icon icon-phone"></ContactIcon> <span >+420 739 649 786</span></Contact>
                <Contact href="https://www.cestu-j.cz/"><ContactIcon className="icon icon-www"></ContactIcon> <span>www.cestu-j.cz</span></Contact>
                <Contact href="https://www.instagram.com/_cestuj_/"><ContactIcon className="icon icon-instagram"></ContactIcon>_cestuj_</Contact>
              </BusinessCard>
            </AddressWrapper>
          </Wrapper>
        </Container>
      </PageContent>
    </>
  )
}

export default ContactPage

export const Head = () => (
  <>
    <PageHead title="Kontakt | Cestu-j" />
  </>
)